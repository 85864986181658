<template>
  <div class="listBg">
    <div class="title">
      {{ title }}
      <!-- <span class="more">更多>></span> -->
    </div>
    <slot></slot>
    <div class="items">
      <div class="item" v-for="(item, idx) in data" :key="idx">
        <!-- <span class="name">{{ item.name }}</span> -->
        <a class="name" :href="item.url" target="_blank">{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: Array,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/public.scss";
.listBg {
  @extend %clearfix;
  padding: 0 10px;
  .title {
    height: 40px;
    line-height: 40px;
    background: transparent;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c01515;
    border-bottom: 1px solid #dbdcdb;
    .more {
      color: #c01515;
      float: right;
    }
  }
  .items {
    padding: 8px 0;
    .item {
      padding: 8px 0;
      display: flex;
      width: 50%;
      float: left;
      .name {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        flex-basis: 50px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        text-align: right;
      }
    }
  }
}
</style>
<template>
  <div class="h5Home">
    <!-- <MyHeader /> -->
    <!-- //End header -->
    <van-swipe class="mySwipe" :autoplay="3000">
      <van-swipe-item v-for="(image, index) in imgArr" :key="index">
        <img v-lazy="image.sliderImg" @click="toDetails(0, index)" />
      </van-swipe-item>
    </van-swipe>

    <div class="main">
      <!-- <notice></notice> -->
      <!-- <van-notice-bar left-icon="volume-o" :text="text">
        <div slot="left-icon">
          <img class="icon-notice" width="20" src="./notice.png" alt="" />
        </div>
      </van-notice-bar> -->
      <!-- 滚动通知 -->

      <div class="panel" style="margin-top: 10px">
        <ListColor :arr="rollList" />
        <!-- <roll-notice :arr="rollList"></roll-notice> -->
      </div>
      <!-- 通知公告 -->

      <div class="panel">
        <ListBg :data="sysList" title="实验室动态" />
      </div>
      <div class="panel">
        <ListBg :data="cyList" title="金融行业动态" />
      </div>
      <div class="panel">
        <ListBg :data="hyList" title="信息产业动态" />
      </div>
      <!-- <div class="panel">
        <ListBg :data="jjfaList" title="解决方案" />
      </div> -->

      <div class="banner" v-if="bannerItem">
        <img :src="bannerItem ? bannerItem.advImg : ''" alt="" @click="toDetails(1)" />
      </div>

      <div class="panel">
        <ListLink :data="cooperationArr" title="合作伙伴">
          <img
            v-if="cooperItem"
            :src="cooperItem.pantnerImg"
            alt=""
            class="cooperate"
            @click="toDetails(3)"
          />
        </ListLink>
      </div>

      <div class="panel">
        <ListLink :data="linkArr" title="友情链接" />
      </div>

      <div class="panel">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/header";
import ListColor from "../components/listColor";
import ListBg from "../components/listBg";
import ListLink from "../components/listLink";
import notice from "../components/notice";
import Footer from "../components/footer";
export default {
  components: {
    // MyHeader,
    ListColor,
    ListBg,
    ListLink,
    Footer,
    // notice,
  },
  data() {
    return {
      cooperItem: null,
      text: "",
      // 轮播图
      imgArr: [],
      // 产业动态
      cyList: [],
      // 行业动态
      hyList: [],
      // 解决方案
      jjfaList: [],
      // 实验室动态
      sysList: [],
      // 滚动数据
      rollList: [],
      // 友情链接
      linkArr: [],
      // 合作伙伴
      cooperationArr: [],
      bannerItem: null,
    };
  },
  created() {
    this.getSliderList();
    this.getTypeList();
    this.getNoticeList();
    this.getLinkList();
    this.getCooperationArr();
    this.getList();
    this.getAdvList();
    this.getPanList();
  },
  methods: {
    async getSliderList() {
      let res = await this.$api.home.sliderList();
      if (res.status == 200) {
        this.imgArr = res.data.records;
      }
    },
    async getTypeList() {
      let res = await this.$api.home.typeList();
      if (res.status == 200) {
        for (let key in res.data) {
          if (res.data[key]) {
            for (let i = 0; i < res.data[key].length; i++) {
              res.data[key][i]["createTime"] = res.data[key][i]["createTime"].substr(
                5,
                6
              );

              if (key == "jjfaList") {
                if (res.data[key][i].description.length >= 40) {
                  res.data[key][i].handleDescription =
                    res.data[key][i].description.substr(0, 40) + "...";
                } else {
                  res.data[key][i].handleDescription = res.data[key][i].description;
                }
              }
            }
          }
        }
        this.cyList = res.data.cyList;
        this.hyList = res.data.hyList;
        this.jjfaList = res.data.jjfaList;
        this.sysList = res.data.sysList;
      }
    },
    async getNoticeList() {
      let _this = this;
      let res = await this.$api.home.noticeList();
      if (res.status == 200) {
        for (let i = 0; i < res.data.records.length; i++) {
          res.data.records[i]["createTime"] = res.data.records[i]["createTime"].substr(
            5,
            6
          );
        }
        _this.rollList = res.data.records;
      }
    },
    async getLinkList() {
      let res = await this.$api.home.linkList();
      if (res.status == 200) {
        this.linkArr = res.data;
      }
    },
    async getCooperationArr() {
      let res = await this.$api.home.cooperationList();
      if (res.status == 200) {
        this.cooperationArr = res.data;
      }
    },
    async getList() {
      let _this = this;
      let res = await this.$api.home.topList();
      if (res.status == 200) {
        for (let i = 0; i < res.data.records.length; i++) {
          this.text += res.data.records[i].title;
        }
      }
    },
    async getAdvList() {
      let res = await this.$api.second.getAdvList();
      if (res.status == 200) {
        this.bannerItem = res.data.records.length > 0 ? res.data.records[0] : null;
      }
    },
    async getPanList() {
      let res = await this.$api.second.getPanList();
      console.log("获取合作伙伴 --- ", res);
      if (res.status == 200) {
        this.cooperItem = res.data.records.length > 0 ? res.data.records[0] : null;
      }
    },
    toDetails(code, index) {
      if (code == 0) {
        // 轮播
        this.$router.push({
          path: "/detail",
          query: { type: 4, articleId: this.imgArr[index].id },
        });
      } else if (code == 1) {
        // 广告
        this.$router.push({
          path: "/detail",
          query: { type: 5, articleId: this.bannerItem.id },
        });
      } else {
        this.$router.push({
          path: "/detail",
          query: { type: 5, articleId: this.cooperItem.id },
        });
      }
    },
  },
};
</script>

<style lang="scss">
body {
  width: 100%;
}
#app {
  width: 100%;
  float: left;
  overflow: hidden;
}
.h5Home {
  background: #ffffff;
  .mySwipe {
    width: 100%;
    height: 200px;
    img {
      width: 100%;
    }
  }
  .main {
    // padding: 0 20px;
  }
  .van-notice-bar {
    background: transparent;
    color: #222222;
    padding: 0 1px;
  }
  .icon-notice {
    margin-top: 7px;
  }
  .panel {
    // margin-bottom: 10px;
    padding-bottom: 10px;
    // padding: 0 10px 10px 10px;
  }
  .banner {
    img {
      width: 100%;
      height: 40px;
    }
  }
  &::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: #f0f2f5;
  }
  .cooperate {
    width: 100%;
    margin-top: 5px;
  }
}
</style>

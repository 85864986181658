<!-- 顶部消息组件 -->
<template>
  <div class="notice">
    <div class="notice-content">
      <div class="news-icon"></div>
      <div class="news-list">
        <div class="notice-content">
          <ul
            ref="notul"
            style="
              margin: 0px;
              left: 0;
              top: 0;
              margin-bottom: 0px;
              width: 100%;
              height: 47px;
              position: absolute;
            "
          >
            <li v-for="(item, index) in arr" :key="index">
              <a
                href="javascript:void(0)"
                class="sstzNoticeStyle"
                @click="toPage(item)"
                >{{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject:['routerRefresh'],   //在子组件中注入在父组件中创建的属性
  data() {
    return {
      arr: [],
      syTimer: null,
      lisTotalWidth: 0,
      resetWidth: 0,
      left: 0,
    };
  },
  created() {
    this.getList();
  },
  beforeDestroy() {
    this.onStop();
  },
  methods: {
    init() {
      let _this = this;
      let lis = this.$refs.notul.getElementsByTagName("li");
      for (var i = 0; i < lis.length; i++) {
        _this.lisTotalWidth += lis[i].offsetWidth;
      }
      for (var j = 0; j < lis.length; j++) {
        _this.resetWidth += lis[j].scrollWidth;
      }
      this.$refs.notul.style.width = _this.lisTotalWidth + "px";
      _this.syTimer = setInterval(function () {
        _this.left -= 1;
        if (_this.left <= -_this.resetWidth) {
          _this.left = 0;
        }
        _this.$refs.notul.style.left = _this.left + "px";
      }, 20);
    },
    onStop() {
      clearInterval(this.syTimer);
    },
    onStart() {
      let _this = this;
      clearInterval(this.syTimer);
      _this.syTimer = setInterval(function () {
        _this.left -= 1;
        if (_this.left <= -_this.resetWidth) {
          _this.left = 0;
        }
        _this.$refs.notul.style.left = _this.left + "px";
      }, 20);
    },
    toPage(item) {
      this.$router.push({ path: "/detail", query: { type: 3, articleId: item.id} });
    },
    async getList() {
      let _this = this;
      let res = await this.$api.home.topList();
      if (res.status == 200) {
        _this.arr = res.data.records;
        _this.$nextTick(function () {
          _this.init();
        });
      }
    },
  },
};
</script>
<style lang="scss">
.notice {
  width: 100%;
  height: 50px;
  .notice-content {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    position: relative;
    overflow: hidden;
    .news-icon {
      width: 22px;
      height: 19px;
      background: url("./../../../assets/images/news.png") no-repeat;
      background-size: 100% 100%;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -6.5px;
    }
    .news-list {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 35px;
      .notice-content {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        ul {
          width: 100%;
          height: 100%;
          li {
            list-style: none;
            display: inline-block;
            float: left;
            height: 50px;
            width: 400px;
            padding-right: 50px;
            line-height: 50px;
            a {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #222222;
              line-height: 17px;
            }
          }
        }
      }
    }
  }

  .sstzNoticeStyle {
    color: #000000;
    font-size: 16px;
    text-decoration: none;
  }

  .sstzNoticeStyle:hover {
    color: #000000;
    font-size: 12px;
    text-decoration: none;
  }
}
</style>
<template>
  <div class="list">
    <div class="title">通知公告</div>
    <div id="news" class="content">
      <ul ref="rolUll">
        <li v-for="(item, index) in arr" :key="index">
          <a href="javascript:void(0)" @click="toPage(item)">
            {{ item.title }}
          </a>
          <span class="time">{{ item.createTime }}</span>
        </li>
      </ul>
    </div>
    <!-- <div class="items" ref="rolUll">
      <div class="item" v-for="(item, idx) in arr" :key="idx">
        <span class="name">{{ item.title }}</span>
        <span class="time">{{ item.time }}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 50,
      syTimer: null,
      lisTotalHeight: 0,
      resetHeight: 0,
      mapTop: 0,
    };
  },
  props: {
    arr: Array,
  },
  watch: {
    arr: {
      handler: function (val) {
        this.$nextTick(function () {
          this.init();
        });
      },
    },
  },
  beforeDestroy() {
    this.onStop();
  },
  methods: {
    init() {
      if (this.arr.length > 5 && this.$refs.rolUll) {
        let _this = this;
        let lis = this.$refs.rolUll.getElementsByTagName("li");
        for (var i = 0; i < lis.length; i++) {
          _this.lisTotalHeight += lis[i].offsetHeight;
        }
        for (var j = 0; j < lis.length - 2; j++) {
          _this.resetHeight += lis[j].offsetHeight;
        }
        this.$refs.rolUll.style.height = _this.lisTotalHeight + "px";
        _this.syTimer = setInterval(function () {
          _this.mapTop -= 1;
          if (_this.mapTop <= -_this.resetHeight) {
            _this.mapTop = 0;
          }
          _this.$refs.rolUll.style.marginTop = _this.mapTop + "px";
        }, _this.num);
      }
    },
    onStop() {
      clearInterval(this.syTimer);
    },
    toPage(item) {
      this.$router.push({ path: "/detail", query: { type: 2, articleId: item.id} });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .title {
    height: 40px;
    line-height: 40px;
    background: #c01515;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    // padding: 0 15px;
    padding-left: 15px;
  }
  .content {
    height: 253px;
    width: 100%;
    // background: #f9f9f9;
    background: #f0f2f5;
    border-radius: 1px;
    overflow: hidden;
    ul {
      padding: 16px 30px;
      li {
        padding-bottom: 23px;
        a {
          width: 216px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 216px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 17px;
          // margin-right: 41px;
          display: inline-block;
          &:hover {
            color: #c01515;
          }
        }
        .time {
          float: right;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #888888;
          line-height: 20px;
        }
      }
    }
  }
  // .items {
  //   padding: 8px 15px;
  //   background: #fff;
  //   .item {
  //     padding: 8px 0;
  //     display: flex;
  //     .name {
  //       font-size: 12px;
  //       font-family: PingFangSC-Medium, PingFang SC;
  //       font-weight: 500;
  //       color: #222222;
  //       flex: 1;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       white-space: nowrap;
  //     }
  //     .time {
  //       flex-basis: 50px;
  //       font-size: 14px;
  //       font-family: PingFangSC-Medium, PingFang SC;
  //       font-weight: 500;
  //       color: #888888;
  //       text-align: right;
  //     }
  //   }
  // }
}
</style>
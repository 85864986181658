<template>
  <div class="footer">
    <div class="hd">
      <p>© 2020 金融信创实验室 版权所有</p>
      <p>京ICP备05026712-4号 本网站支持IPV6访问</p>
      <div class="record">
        <i class="security"></i>
        <span>京公网安备 11010202009095号</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "./../../../assets/style/public.scss";
.footer {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  text-align: center;
  margin: 10px;
  border-top: 1px solid #dbdcdb;
  padding-top: 20px;
  .hd {
    .record {
      display: flex;
      justify-content: center;
      .security {
        @include bgImg("../../../assets/images/WechatIMG534.png", 15px, 15px);
        display: inline-block;
      }
    }
  }
}
</style>

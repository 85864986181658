<template>
  <div class="listBg">
    <div class="title">
      {{ title }}<span class="more" @click="toPageList">更多>></span>
    </div>
    <div class="items">
      <div
        class="item"
        v-for="(item, idx) in data"
        :key="idx"
        @click="toPage(item)"
      >
        <span class="name">{{ item.title }}</span>
        <span class="time">{{ item.createTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getTreeItem } from "@/utils/index";
export default {
  props: {
    title: String,
    data: Array,
  },
  methods: {
    toPage(article) {
      let myMenus = JSON.parse(sessionStorage.getItem("menus"));
      let item = getTreeItem(myMenus, this.title, "name");
      this.$router.push({
        path: "/detail",
        query: { type: 1, sid: item.id, articleId: article.id },
      });
    },
    toPageList() {
      let myMenus = JSON.parse(sessionStorage.getItem("menus"));
      let item = getTreeItem(myMenus, this.title, "name");
      let param = {
        pid: item.pid,
        sid: item.id,
      };
      this.$router.push({ path: "/list", query: param });
    },
  },
};
</script>

<style lang="scss" scoped>
.listBg {
  padding: 0 10px;
  border-radius: 1px;
  background: #f0f2f5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .title {
    height: 40px;
    line-height: 40px;
    background: transparent;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c01515;
    border-bottom: 1px solid #dbdcdb;
    .more {
      color: #c01515;
      float: right;
    }
  }
  .items {
    padding: 8px 0;
    .item {
      padding: 8px 0;
      display: flex;
      .name {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        flex-basis: 50px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        text-align: right;
      }
    }
  }
}
</style>